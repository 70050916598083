var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-5 q-px-md" },
        [
          _c("h5", { staticClass: "q-mt-sm" }, [_vm._v("Dati della pratica")]),
          _c(
            "q-list",
            { attrs: { separator: "" } },
            [
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Cliente:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.name)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Stato della pratica:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.getStatoPratica)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [
                        _vm._v("Unità operativa richiedente:"),
                      ]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.unita_operativa)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Persona operativa:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.operatore)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Numero pratica:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.numero)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Numero interno:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.RecordSelezionato.numero_interno)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [
                        _vm._v("Data di decorrenza richiesta:"),
                      ]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.getDataDecorrenzaPratica)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Note:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.annotazione)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-7" },
        [
          _c("h5", { staticClass: "q-mt-sm" }, [_vm._v("Elenco dei problemi")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isProblemiPresenti,
                  expression: "!isProblemiPresenti",
                },
              ],
            },
            [
              _c("h5", [
                _vm._v(
                  "Nessun problema è stato identificato per questa pratica."
                ),
              ]),
            ]
          ),
          _c(
            "q-list",
            { attrs: { separator: "", bordered: "" } },
            _vm._l(_vm.elenco_problemi, function (problema, index) {
              return _c(
                "q-item",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  key: index,
                  attrs: { clickable: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onSelezionaProblema(problema)
                    },
                  },
                },
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Descrizione problema:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(problema.note)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Segnalato da:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(problema.personaoperativa)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Data segnalazione:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          _vm._s(_vm.getDataProblema(problema.segnalato_at))
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Problema risolto:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.isProblemaRisolto(problema))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [
                        _vm._v("Data risoluzione problema:"),
                      ]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          _vm._s(_vm.getDataProblema(problema.risolto_at))
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    { attrs: { avatar: "" } },
                    [
                      _c("q-avatar", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !problema.is_risolto,
                            expression: "!problema.is_risolto",
                          },
                        ],
                        attrs: {
                          color: "orange-3",
                          "text-color": "black",
                          icon: "mdi-download",
                        },
                      }),
                      _c("q-avatar", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: problema.is_risolto,
                            expression: "problema.is_risolto",
                          },
                        ],
                        attrs: {
                          color: "orange-3",
                          "text-color": "black",
                          icon: "mdi-check-outline",
                          size: "36px",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "col-12 col-md-12", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "INDIETRO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onClickIndietro()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("h4", [_vm._v("Problemi segnalati sulla pratica")]),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12" }, [_c("br"), _c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }